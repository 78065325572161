import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../common/Avatar';
import team3 from '../../assets/img/team/3.jpg';
import NavbarDropdown from "./NavbarDropdown";
import Login from "../auth/basic/Login";
import Divider from "../common/Divider";

const ProfileDropdown = (props) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav className="pr-0">
      <Avatar src={props.currentUser.photoURL} />
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-card">
      <div className="bg-white rounded-soft py-2">
        <DropdownItem tag={Link} to="/tutorials">
          Lectures
        </DropdownItem>
        <Divider />
        <DropdownItem tag={Link} to="/homework">
          Homework
        </DropdownItem>
        <Divider />
        <DropdownItem tag={Link} to="/students">
          Students
        </DropdownItem>
        <Divider />
        <DropdownItem divider />
          <Card className="navbar-card-login shadow-none">
            <CardBody className="fs--1 font-weight-normal p-4">
              <Login/>
            </CardBody>
          </Card>
      </div>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default ProfileDropdown;
