import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Collapse, Navbar, NavbarToggler, NavItem, Nav, NavLink, Card, CardBody} from 'reactstrap';
import AppContext, { ProductContext } from '../../context/Context';
import Logo from './Logo';
import SearchBox from './SearchBox';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import NavbarDropdown from "./NavbarDropdown";
import Login from "../auth/basic/Login";
import Firebase from "../../Firebase";

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const { shoppingCart } = useContext(ProductContext);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    Firebase.auth.onAuthStateChanged(setCurrentUser)
  });

  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit ml-auto" expand>
      {/*<NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />*/}
      <Logo at="navbar-top" width={40} id="topLogo" />
      <Collapse navbar>
        <Nav navbar className="align-items-center ml-auto">
          {currentUser ?
              <Fragment>
                {/*<NotificationDropdown/>*/}
                <ProfileDropdown currentUser={currentUser} />
              </Fragment> :
              <NavbarDropdown title={currentUser ? currentUser.displayName : 'Login'} right>
                <Card className="navbar-card-login shadow-none">
                  <CardBody className="fs--1 font-weight-normal p-4">
                    <Login/>
                  </CardBody>
                </Card>
              </NavbarDropdown>
          }
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarTop;
