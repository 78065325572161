import React, {Component, Fragment} from "react"
import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {toast} from "react-toastify";
import {Row} from "reactstrap";
import { withRouter } from 'react-router-dom'
import Firebase from "../../Firebase";

class FirebaseUiLogin extends Component{
    state = {isSignedIn: false}
    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            { provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                scopes: [
                    'public_profile',
                    'email'
                ]
            },
            { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                scopes: ['https://www.googleapis.com/auth/userinfo.email']
            }
            // firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                toast.success(`Logged in as ${authResult.user.displayName}`);
                return false;
            }
        }
    }

    componentDidMount() {
        Firebase.auth.onAuthStateChanged(user => {
            this.setState({isSignedIn: !!user})
            if (user && this.props.match.path === '/auth/login') {
                this.props.history.replace('/');
            }
        })
    }

    signOut = () => {
        Firebase.auth.signOut().then(() =>
            toast.success(`Logged out`))
    };

    render() {
        const { isSignedIn } = this.state;
        return (
            <div>
                {isSignedIn && firebase.auth().currentUser
                    ? <Row className="text-left justify-content-center">
                        <h1>{firebase.auth().currentUser.displayName}</h1>
                        <input type="submit" value="Logout" className="btn btn-secondary btn-lg btn-block" onClick={this.signOut} />
                    </Row>
                    : <div>
                        <Row className="text-left justify-content-center"><h3>Registration</h3></Row>
                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={Firebase.auth} />
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(FirebaseUiLogin);