import iconZip from '../../assets/img/icons/zip.png';
import iconDocs from '../../assets/img/icons/docs.png';

export default [
  {
    id: 1,
    img: iconDocs,
    name: 'Syllabus.pdf',
    path: 'Syllabus.pdf',
    user: 'Rashedul Khan',
    time: 'January 21st, 2020'
  },
  {
    id: 2,
    img: iconZip,
    name: 'tic-tac-toe.zip',
    path: 'tic-tac-toe.zip',
    user: 'Rashedul Khan',
    time: 'January 21st, 2020'
  },
  {
    id: 3,
    img: iconZip,
    name: 'OpenJDK8-mac.pkg',
    path: 'OpenJDK8-mac.pkg',
    user: 'Rashedul Khan',
    time: 'January 21st, 2020'
  },
  {
    id: 4,
    img: iconZip,
    name: 'OpenJDK8-windows.msi',
    path: 'OpenJDK8-windows.msi',
    user: 'Rashedul Khan',
    time: 'January 21st, 2020'
  }
];
